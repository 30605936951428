<template>
  <v-container class="bg-glass-effect ma-0 pa-0" style="overflow-x: hidden;" fluid>
      <BackArrow v-if="isLoadCards" :action="() => onClickBack()"/>

      <v-row class="px-0 mx-0 my-0">
        <v-col class="pb-0 pt-13 mt-1 mb-0 mx-3" cols="12">
          <h2 class="px-0 mx-0" style="color: #532E88">Carteirinha</h2>
        </v-col>

        <v-col class="subtitle-name pt-0 mt-1 mx-3" cols="12">
          <p class="pa-0 ma-0">{{benefit}} - Operadora {{carrier.name}}</p>
        </v-col>

        <v-col class="px-0 mx-0" cols="12">
          <div style="padding-top: 95px;" v-for="(card, index) in cards" :key="index">
            <v-card elevation="8" min-width="120vw" min-height="85vw" id="cardVertical" style="margin-top: 20px; margin-bottom: 60px; border-radius: 30px; right: 39px" :style="style">
              <v-card-text class="py-0 my-0">
                <v-row class="px-3">
                  <v-col class="px-0 py-6 ma-2" cols="12">
                    <div class="row" v-html="card.cardProcessTemplate ? card.cardProcessTemplate : cardTemplateIfNotExist"></div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card><br>
          </div>
        </v-col>

        <v-col cols="12" class="pt-15" align="center">
          <v-btn class="btn-site" depressed rounded color="#D6DAFF" @click="openUrl()">
            {{ linkText }}
            <img class="ml-6" style="width: 28px; height: 23px" src="/img/icons/common/right-arrow.svg"/>
          </v-btn>
        </v-col>

        <v-col v-if="carrier && carrier.phone" cols="12" align="center" class="subtitle-name">
          <v-row justify="center">
            <v-icon color="primary" class="pr-2 text">fas fa-phone-square</v-icon><span>{{ this.carrier.phone }}</span>
          </v-row>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import BackArrow from '@/components/covid/back-arrow/index'
  import MobileDetect from "../../plugins/mobile-detect";
  export default {
    name: "walletDetails",
    data: () => ({
      fromRouteBcp: [],
      cards: [],
      cardTemplateIfNotExist: '<p style=\'text-align:center; color:white\'>Não foi possível carregar os dados da carteirinha</p>',
      carrier: {
        name: '',
        phone: null,
      },
      benefit: '',
      appAddress: '',
      appAddressIos: '',
      isLoadCards: false,
      style: {
        background: 'linear-gradient(135deg, rgba(83, 46, 136, 1) 40%, rgba(108, 88, 133, 1) 100%)',
        color: 'white',
      }
    }),
    components: {
      BackArrow
    },
    computed: {
      linkText() {
        let md = new MobileDetect(window.navigator.userAgent);

        if(md.is('iOS') || md.is('WindowsPhoneOS') || md.is('AndroidOS')) {
          return 'Ir para o aplicativo'
        }

        return 'Ir para o site'
      },
    },
    created () {
      this.style = this.$route.params.style ? this.$route.params.style : this.style
      this.cards = this.$route.params.cards ? this.$route.params.cards : this.cards
      this.fromRouteBcp = this.$route.params.fromRouteBcp ? this.$route.params.fromRouteBcp : this.fromRouteBcp

      if(this.cards.length != 0){
        this.carrier.phone = this.cards[0].carrierPhone ? this.cards[0].carrierPhone : this.carrier.phone
        this.carrier.name = this.cards[0].carrierName ? this.cards[0].carrierName : this.carrier.name
        this.benefit = this.cards[0].benefitName ? this.cards[0].benefitName : this.benefit
        this.appAddress = this.cards[0].carrierAppAddress ? this.cards[0].carrierAppAddress : ''
        this.appAddressIos = this.cards[0].carrierAppAddressIos ? this.cards[0].carrierAppAddressIos : ''
        this.isLoadCards = true
      } else {
        this.$router.push({name: "wallet"})
      }
    },
    methods: {
      onClickBack() {
        this.$router.push({ name: 'walletList', params: { beneficiaryContractedPlans: this.fromRouteBcp } });
      },
      openUrl () {
        let link = ''
        try{
          link = this.$util.getUrlApp(JSON.parse(this.appAddress))
        } catch(e){
          if (this.$util.isIosMobile()) {
            link = this.appAddressIos
          } else {
            link = this.appAddress
          }
        } finally {
          window.location.href = link
        }
      }
    }
  }
</script>

<style scoped>
#cardVertical{
  -webkit-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  transform: rotate(270deg);
}

/*background effect blur*/
.bg-glass-effect{
  background: rgba(255,255,255,.8) !important;
  mix-blend-mode: normal !important;
  backdrop-filter: blur(100px) !important;
  height: 100vh;
  width: 100vw;
}

.subtitle-name {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #868698;
  font-weight: bold;
}

.btn-site {
  text-transform: initial !important;
  color: #4A499B;
  font-weight: bold;
}

</style>


